import React from "react";
import Logo from "../../assets/Logo.svg";
import Vehicle from "../../assets/Vehicle.svg";
import Googleplay from "../../assets/Png/Googleplay.png";
import Appstore from "../../assets/Png/Applestore.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidecard = ({
  isFromSearchcart,
  imageData,
  OnVendor,
  Property,
  helpsupport,
}) => {
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user = useSelector((state) => state.user);

  const logo = selectedVendor?.logo ?? user?.default_vendor?.logo;

  return (
    <div className="w-[90%] bg-white h-[670px] border border-gray-200 rounded-lg">
      <div className="flex flex-col justify-end pt-4 items-center pb-10">
        <div>
          {Property || OnVendor || helpsupport ? (
            <img
              className="lg:w-[220px] w-[137px] h-[210px] mx-auto "
              src={Logo}
            />
          ) : (
            <img
              className="lg:w-[220px] w-[137px] h-[210px] mx-auto rounded-full "
              src={logo}
            />
          )}
        </div>

        {isFromSearchcart ? (
          <img
            className="w-[268px] h-[241px] md:mt-[30px] rounded-md shadow-lg "
            src={imageData ? imageData : require('../../assets/lsv_1.png') || logo}
          />
        ) : (
          <img className="w-[268px] h-[241px] md:mt-[10px] " src={require('../../assets/lsv_1.png')} />
        )}

        <h5 className="lg:mt-[21px] md:mt-[30px] text-Textlitecolor lg:text-[20px] text-[16px] font-medium">
        Download the Ridewise App{" "}
        </h5>
        <div className="flex xl:flex-row md:flex-col lg:gap-4 lg:mt-2 md:mt-5 mt-1 cursor-pointer">
          <Link to="https://apps.apple.com/pk/app/rentals-reimagined/id1672577637">
            <img
              className="lg:h-[50px] md:w-[140px] md:h-[50px] sm:w-[95px] sm:h-[32px]  "
              src={Appstore}
            />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.app.ridewiserentalsreimagined">
            <img
              className="lg:h-[50px] md:w-[140px] md:h-[50px] sm:w-[95px] sm:h-[32px]  "
              src={Googleplay}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidecard;
